@keyframes ShowcaseAnimation { 
  0% { 
    opacity: 0; 
    animation-timing-function: ease-in;
  }
  5% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  25% {
    opacity: 1
  }
  30% {
    opacity: 0
  }
}