@media (min-width: 768px) {
   .image-gallery-icon:hover {
      color: #F18F33; 
   }
   .image-gallery-icon:hover .image-gallery-svg {
      transform: scale(1.1); 
   } 
}
.image-gallery-icon:focus {
   outline: none; 
}


.image-gallery-bullets .image-gallery-bullet:focus, 
.image-gallery-bullets .image-gallery-bullet:hover {
   background: #F18F33;
}

.image-gallery-bullets .image-gallery-bullet.active {
   background: #fff; 
}

.image-gallery-thumbnail.active, 
.image-gallery-thumbnail:hover, 
.image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #F18F33; 
}
@media (max-width: 768px) {
   .image-gallery-thumbnail.active, 
   .image-gallery-thumbnail:hover, 
   .image-gallery-thumbnail:focus {
      border: 3px solid #F18F33; 
   } 
}

.image-gallery-slide:focus {
  outline: 0;
}