@keyframes ShowAnimation { 
   0% { 
      opacity: 0; 
      animation-timing-function: ease-in;
   }
   5% {
      opacity: 1;
      animation-timing-function: ease-out;
   }
   33% {
      opacity: 1
   }
   38% {
      opacity: 0
   }
}