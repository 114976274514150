.glide {
   width: 80%;
}

.glide__arrow {
   border-radius: 100%;
   text-align: center;
}

.glider-show-type {
   letter-spacing: 3px
}

.glider-info {
   padding: 0 20px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   letter-spacing: 1px
}

.glider-more {
   font-weight: 700;
   color: white;
   text-decoration: none;
   letter-spacing: 1px;
}

.glider-hr {
   width: 90%;
   border-color: rgba(255, 255, 255, 0.2)
}

.glider-standard-img {
   background: url('../../assets/images/shows/show-standard-1.jpg') center/cover no-repeat;
}
.glider-premium-img {
   background: url('../../assets/images/shows/show-premium-1.jpg') center/cover no-repeat;
}
.glider-costume-img {
   background: url('../../assets/images/shows/show-costume-1.jpg') top/cover no-repeat;
}
.glider-special-img {
   background: url('../../assets/images/shows/show-special-1.jpg') center/cover no-repeat;
}
.glider-standard-img,
.glider-premium-img,
.glider-costume-img,
.glider-special-img {
   width: 100%;
   height: 350px;
}


@media (max-width: 1200px) {
   .glider-standard-img,
   .glider-premium-img,
   .glider-costume-img,
   .glider-special-img {
      height: 300px;
   }
}
@media (max-width: 768px) {
   .glide {
      width: 70%
   }
}
@media (max-width: 575.98px) {
   .glide {
      width: 90%
   }
}